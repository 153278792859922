import PropTypes from "prop-types"
import React from "react"

const Video = ({ anchor, videoId, lang }) => (
    <section id={anchor}>
        <div className="video-responsive">
            <iframe 
                src={`https://www.youtube.com/embed/${videoId}?enablejsapi=1&origin=https://www.alexandfriends.gr/${lang}/`}
                title={`Video ${videoId}`}
                frameBorder={0} width={560} height={315} allowFullScreen
            />
        </div>
    </section>
)

Video.propTypes = {
    anchor: PropTypes.string,
    videoId: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired,
}

export default Video