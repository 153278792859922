import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { Container } from "react-bootstrap"

const Breadcrumbs = ({ items, pageTitle }) => {
    return (
        <section className="breadcrumbs">
            <Container>
                <ol>
                    {items.map(item => (
                        <li key={item.id}>
                            {(item.url && 
                                <Link to={item.url} title={item.title}>{item.title}</Link>) ||
                                <>{item.title}</>}
                        </li>
                    ))}
                </ol>
                <h1>{pageTitle}</h1>
            </Container>
        </section>
    )
}

Breadcrumbs.propTypes = {
    items: PropTypes.array.isRequired,
    pageTitle: PropTypes.string.isRequired,
}

export default Breadcrumbs