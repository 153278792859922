import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image"

import { Container, Row, Col } from "react-bootstrap"
import { FaLink } from "react-icons/fa"

import { RichTextElement } from "@kentico/gatsby-kontent-components"

const VideoList = ({ anchor, sectionTitle, items, oddColumn, lang }) => (
    <section className="portfolio" style={{ padding: 0 }} id={anchor}>
        <Container>
            {sectionTitle && (
                <div className="section-title">
                    <RichTextElement value={sectionTitle} />
                </div>
            )}
            <Row className="portfolio-container">
                {items.map(item => {
                    const videoTitle = item.elements.title.value
                    const videoUrl = `https://www.youtube.com/watch?v=${item.elements.video_id.value}&enablejsapi=1&origin=https://www.alexandfriends.gr/${lang}/`
                    return (
                        <Col key={item.id} lg={oddColumn ? 4 : 3} md={6} className="portfolio-item">
                            <a href={videoUrl} title={videoTitle} rel="noreferrer" target="_blank">
                                <Img fluid={item.localImage.childImageSharp.fluid} className="img-fluid" alt={videoTitle} />
                            </a>
                            <a href={videoUrl} className="portfolio-info" title={videoTitle} rel="noreferrer" target="_blank">
                                <h4>{videoTitle}</h4>
                                <span className="details-link">
                                    <FaLink />
                                </span>
                            </a>
                        </Col>
                    )
                })}
            </Row>
        </Container>
    </section>
)

VideoList.propTypes = {
    anchor: PropTypes.string,
    sectionTitle: PropTypes.string,
    items: PropTypes.array.isRequired,
    oddColumn: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
}

export default VideoList