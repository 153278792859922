import PropTypes from "prop-types"
import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { FaWindowClose, FaBars } from "react-icons/fa"

import { useComponentVisible } from "../../components/is-component-visible"

const NavItems = ({ lang, navItems, breadcrumb, otherLangUrl }) => {
    const data = useStaticQuery(graphql`
      query {
        uk: file(relativePath: { eq: "flag-uk.png" }) {
          childImageSharp {
            fixed(width: 32) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        el: file(relativePath: { eq: "flag-gr.png" }) {
          childImageSharp {
            fixed(width: 32) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `)
  
    const activeAnchor =
        breadcrumb.length > 1 ?
            navItems.find(nav => nav.id === breadcrumb[1].id)?.elements?.anchor?.value :
            ""

    return (
        <ul>
            <li>
                <Link to={`/${lang}`}>{lang === "el" ? "Αρχική" : "Home"}</Link>
            </li>
            {navItems.filter(item => item.elements.anchor.value).map(item => (
                <li key={item.elements.anchor.value} className={activeAnchor === item.elements.anchor.value ? "active" : null}>
                    <Link to={`/${lang}#${item.elements.anchor.value}`}>{item.elements.title.value}</Link>
                </li>
            ))}
            {lang === "el" && (
            <li className="language">
                <Link to={otherLangUrl} title="English">
                    <Img fixed={data.uk.childImageSharp.fixed} alt="English" />
                    <span>English</span>
                </Link>
            </li>
        )}
        {lang === "en" && (
            <li className="language">
                <Link to={otherLangUrl} title="Ελληνικά">
                    <Img fixed={data.el.childImageSharp.fixed} alt="Ελληνικά" />
                    <span>Ελληνικά</span>
                </Link>
            </li>
        )}
        </ul>
    )
}

const Navigation = (props) => {
    const {
        ref,
        isComponentVisible,
        setIsComponentVisible
    } = useComponentVisible(false)

    return (
        <>
            <button aria-label="Toggle navigation" type="button" onClick={() => setIsComponentVisible(!isComponentVisible)} className="mobile-nav-toggle d-lg-none">
                {!!isComponentVisible && <FaWindowClose />}
                {!isComponentVisible && <FaBars />}
            </button>
            <nav className="nav-menu d-none d-lg-block">
                <NavItems {...props} />
            </nav>
            <div ref={ref} className={isComponentVisible ? "mobile-nav-active" : null}>
                <nav className="mobile-nav d-lg-none">
                    <NavItems {...props} onAnchorClick={() => setIsComponentVisible(false)} />
                </nav>
            </div>
            <div className="mobile-nav-overly" style={{ display: isComponentVisible ? "block" : "none" }} />
        </>
    )
}

Navigation.propTypes = {
    lang: PropTypes.string.isRequired,
    navItems: PropTypes.array.isRequired
}

export default Navigation