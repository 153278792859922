import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"

import parse from "html-react-parser"
import innerText from "react-innertext"

import { Container } from "react-bootstrap"
import { RichTextElement } from "@kentico/gatsby-kontent-components"

const RichText = ({ anchor, title, links, sitemap, body, lang, withShadow }) => (
    <section className="blog" id={anchor}>
        <Container>
            <article className="entry entry-single" style={{ boxShadow: withShadow ? null : "none" }}>
                {title && innerText(parse(title)) && (
                    <h2 className="entry-title">
                        <RichTextElement value={title} />
                    </h2>
                )}
                {body && innerText(parse(body)) && (
                    <div className="entry-content">
                        <RichTextElement
                            value={body}
                            links={links}
                            resolveLink={(link, domNode) => {
                                const url = sitemap.find(n =>
                                    n.elements.content_page && n.elements.content_page.value && n.elements.content_page.value.length &&
                                    n.elements.content_page.value[0].system?.id === link.link_id)?.url || "/"
                                return (
                                    <Link to={url}>
                                        {domNode.children[0].data}
                                    </Link>
                                )
                            }} />
                    </div>
                )}
            </article>
        </Container>
    </section>
)

RichText.propTypes = {
    anchor: PropTypes.string,
    title: PropTypes.string,
    body: PropTypes.string.isRequired,
    withShadow: PropTypes.bool
}

export default RichText