import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/Content/header"
import Breadcrumbs from "../components/Content/breadcrumbs"
import PageList from "../components/Content/page-list"
import AssetList from "../components/Content/asset-list"
import RichText from "../components/Content/rich-text"
import Video from "../components/Content/video"
import VideoList from "../components/Content/video-list"

import Share from "../components/share"

function getOtherLink(url, currLang, fullSitemap) {
  const otherLang = currLang === "el" ? "en" : "el"
  const currItem = fullSitemap.nodes.find(i => i.url === url)
  if (!currItem) {
    return `/${otherLang}/`
  }

  const otherItem = fullSitemap.nodes.find(i =>
    i.fields.lang === otherLang &&
    i.elements.content_page.value && i.elements.content_page.value.length &&
    i.elements.content_page.value[0].system.id === currItem.elements.content_page.value[0].system.id);
  if (!otherItem) {
    return `/${otherLang}/`
  }

  return otherItem.url
}

function getHrefLangs(url, fullSitemap) {
  const currItem = fullSitemap.nodes.find(i => i.url === url)
  const result = {}
  if (!currItem) {
    return result
  }

  fullSitemap.nodes
    .filter(i =>
      i.elements.content_page.value && i.elements.content_page.value.length &&
      i.elements.content_page.value[0].system.id === currItem.elements.content_page.value[0].system.id)
    .forEach(i => {
      result[i.fields.lang] = i.url
    });

  return result
}

const ContentPage = (props) => {
  const { data } = props
  const lang = data.page.system.language
  const crumbs = data.currNav.breadcrumb
  return (
    <Layout>
      <SEO
        lang={lang}
        title={data.page.elements.metaTitle.value}
        description={data.page.elements.metaDescription.value}
        ogTitle={data.page.elements.ogTitle.value}
        ogDescription={data.page.elements.ogDescription.value}
        ogImageUrl={
          (data.page.elements.ogImage.value &&
            data.page.elements.ogImage.value.length &&
            data.page.elements.ogImage.value[0].url) || null
        }
        hrefLangs={getHrefLangs(data.currNav.url, data.fullSitemap)}
      />
      <Header
        siteTitle={data.page.elements.metaTitle.value}
        navItems={data.nav.elements.subitems.value}
        lang={lang}
        breadcrumb={crumbs}
        otherLangUrl={getOtherLink(data.currNav.url, lang, data.fullSitemap)}
      />
      <Share
        url={`${data.site.siteMetadata.siteUrl}${data.currNav.url}`}
        title={data.page.elements.metaTitle.value}
      />
      <main id="main">
        <Breadcrumbs items={crumbs} pageTitle={data.page.elements.metaTitle.value} />
        {data.page.elements.modules && data.page.elements.modules.value && data.page.elements.modules.value.map(m => {
          switch (m.system.type) {
            case `rich_text_module`:
              return (
                <RichText
                  key={m.id}
                  title={m.elements.basic_module__module_section_title.value}
                  anchor={m.elements.basic_module__anchor.value}
                  links={m.elements.body.links}
                  body={m.elements.body.value}
                  sitemap={data.sitemap.nodes}
                  lamg={lang}
                  withShadow={m.elements.presentation.value && m.elements.presentation.value.length && m.elements.presentation.value[0].codename === "with_shadow"}
                />
              )
            case `page_list_module`:
              const items = m.elements.categories.value.map(i => ({
                id: i.id,
                name: i.elements.name.value,
                url: data.sitemap.nodes
                  .find(n =>
                    n.elements.content_page && n.elements.content_page.value && n.elements.content_page.value.length &&
                    n.elements.content_page.value[0].id === i.id)?.url || "",
                thumbnail: i.elements.thumbnail_image.value[0]
              }))
              return (
                <PageList
                  key={m.id}
                  anchor={m.elements.basic_module__anchor && m.elements.basic_module__anchor.value}
                  sectionTitle={m.elements.basic_module__module_section_title && m.elements.basic_module__module_section_title.value}
                  items={items}
                  oddColumn={m.elements.card_listing__layout.value && m.elements.card_listing__layout.value.length && m.elements.card_listing__layout.value[0].codename === 'odd_column'}
                />
              )
            case `video_module`:
              return (
                <Video
                  key={m.id}
                  videoId={m.elements.video_id.value}
                  lang={lang}
                />
              )
            case `external_video_list_module`:
              return (
                <VideoList
                  key={m.id}
                  anchor={m.elements.basic_module__anchor && m.elements.basic_module__anchor.value}
                  sectionTitle={m.elements.basic_module__module_section_title && m.elements.basic_module__module_section_title.value}
                  items={m.videos}
                  oddColumn={m.elements.card_listing__layout.value && m.elements.card_listing__layout.value.length && m.elements.card_listing__layout.value[0].codename === 'odd_column'}
                  lang={lang}
                />
              )
            case `asset_list_module__via_category_`:
            case `asset_list_module__via_tags_`:
              return (
                <AssetList
                  key={m.id}
                  anchor={m.elements.basic_module__anchor && m.elements.basic_module__anchor.value}
                  sectionTitle={m.elements.basic_module__module_section_title && m.elements.basic_module__module_section_title.value}
                  items={m.assets}
                  oddColumn={m.elements.card_listing__layout.value && m.elements.card_listing__layout.value.length && m.elements.card_listing__layout.value[0].codename === 'odd_column'}
                />
              )
            default:
              return (<></>)
          }
        })}
      </main>
    </Layout>
  )
}

export default ContentPage

export const query = graphql`
  query contentPageQuery($lang: String!, $url: String!, $id: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    currNav: kontentItemNavigationItem(fields: {lang: {eq: $lang}}, url: {eq: $url}) {
      id
      fields {
        lang
      }
      url
      breadcrumb {
        id
        title
        url
      }
    }

    nav: kontentItemNavigationItem(system: {codename: {eq: "root"}, language: {eq: $lang}}) {
      fields {
        lang
      }
      elements {
        subitems {
          value {
            ... on kontent_item_navigation_item {
              id
              elements {
                title {
                  value
                }
                anchor {
                  value
                }
              }
            }
          }
        }
      }
    }

    sitemap: allKontentItemNavigationItem(filter: {fields: {lang: {eq: $lang}}}) {
      nodes {
        fields {
          lang
        }
        url
        elements {
          content_page {
            value {
              id
              system {
                id
              }
            }
          }
        }
      }
    }

    fullSitemap: allKontentItemNavigationItem {
      nodes {
        url
        fields {
          lang
        }
        elements {
          content_page {
            value {
              system {
                id
              }
            }
          }
        }
      }
    }

    page: kontentItemContentPage(fields: {lang: {eq: $lang}}, system: {id: {eq: $id}}) {
      fields {
        lang
      }
      system {
        language
        id
      }
      elements {
        metaTitle: metadata__meta_title {
          value
        }
        metaDescription: metadata__meta_description {
          value
        }
        ogTitle: metadata__og_title {
          value
        }
        ogDescription: metadata__og_description {
          value
        }
        ogImage: metadata__og_image {
          value {
            url
          }
        }
        name {
          value
        }
        modules {
          value {
            ... on kontent_item_rich_text_module {
              id
              elements {
                basic_module__anchor {
                  value
                }
                basic_module__module_section_title {
                  value
                }
                body {
                  value
                  links {
                    link_id
                  }
                }
                presentation {
                  value {
                    codename
                  }
                }
              }
            }
            ... on kontent_item_asset_list_module__via_category_ {
              id
              assets {
                elements {
                  title {
                    value
                  }
                  thumbnail_image {
                    value {
                      fluid(maxWidth: 400) {
                        ...KontentAssetFluid_withWebp
                      }
                    }
                  }
                  image {
                    value {
                      url
                    }
                  }
                  downloadable_material {
                    value {
                      url
                    }
                  }
                }
              }
              elements {
                basic_module__anchor {
                  value
                }
                basic_module__module_section_title {
                  value
                }
                card_listing__layout {
                  value {
                    codename
                  }
                }
                content_categories {
                  value {
                    codename
                  }
                }
              }
            }
            ... on kontent_item_asset_list_module__via_tags_ {
              id
              assets {
                elements {
                  title {
                    value
                  }
                  thumbnail_image {
                    value {
                      fluid(maxWidth: 400) {
                        ...KontentAssetFluid_withWebp
                      }
                    }
                  }
                  image {
                    value {
                      url
                    }
                  }
                  downloadable_material {
                    value {
                      url
                    }
                  }
                }
              }
              elements {
                basic_module__anchor {
                  value
                }
                basic_module__module_section_title {
                  value
                }
                card_listing__layout {
                  value {
                    codename
                  }
                }
                tags {
                  value {
                    codename
                  }
                }
              }
            }
            ... on kontent_item_video_module {
              id
              elements {
                video_id {
                  value
                }
              }
            }
            ... on kontent_item_page_list_module {
              id
              elements {
                basic_module__anchor {
                  value
                }
                basic_module__module_section_title {
                  value
                }
                card_listing__layout {
                  value {
                    codename
                  }
                }
                categories {
                  value {
                    ... on kontent_item_content_page {
                      id
                      system {
                        codename
                      }
                      elements {
                        thumbnail_image {
                          value {
                            fluid(maxWidth: 400) {
                              ...KontentAssetFluid_withWebp
                            }
                          }
                        }
                        name {
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on kontent_item_external_video_list_module {
              id
              videos {
                id
                elements {
                  title {
                    value
                  }
                  video_id {
                    value
                  }
                }
                localImage {
                  childImageSharp {
                    fluid(maxWidth: 800) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              elements {
                basic_module__anchor {
                  value
                }
                basic_module__module_section_title {
                  value
                }
                card_listing__layout {
                  value {
                    codename
                  }
                }
                video_categories {
                  value {
                    codename
                  }
                }
              }
            }
            system {
              type
              language
            }
          }
        }
      }
    }
  }
`