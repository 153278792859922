import PropTypes from "prop-types"
import React, { useState } from "react"
import Img from "gatsby-image"

import { Container, Row, Col } from "react-bootstrap"
import { FaDownload } from "react-icons/fa"
import { IconContext } from "react-icons"

import { RichTextElement } from "@kentico/gatsby-kontent-components"

import Lightbox from "lightbox-react"
import "lightbox-react/style.css"

const AssetList = ({ anchor, sectionTitle, items, oddColumn }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [photoIndex, setPhotoIndex] = useState(0)

    const imageInfo = Array.from(items.map((item, i) => {
        const downloadUrl = item.elements.downloadable_material.value &&
            item.elements.downloadable_material.value.length &&
            item.elements.downloadable_material.value[0].url

        return {
            imageUrl: item.elements.image && item.elements.image.value && item.elements.image.value.length && `${item.elements.image.value[0].url}?h=600`,
            thumbnail: item.elements.thumbnail_image.value[0].fluid,
            downloadUrl
        }
    }))

    const toolbarButtons = [
        <a
            href={imageInfo[photoIndex].downloadUrl}
            download
            key="download"
            aria-label="Download"
            style={{
                margin: '0 10px',
                display: imageInfo[photoIndex].downloadUrl ? "inline" : "none"
            }}
            className={[
                'ril-download',
                'ril__toolbarItemChild',
                'ril__builtinButton',
                'ril__downloadButton',
                ...(imageInfo[photoIndex].downloadUrl ? [] : ['ril__builtinButtonDisabled']),
            ].join(' ')}
        >
            <IconContext.Provider value={{ color: '#eee', size: '20px' }}>
                <FaDownload />
            </IconContext.Provider>
        </a>
    ]

    return (
        <section className="portfolio" id={anchor} style={{ padding: 0 }}>
            <Container>
                {sectionTitle && (
                    <div className="section-title">
                        <RichTextElement value={sectionTitle} />
                    </div>
                )}
                <Row className="portfolio-container">
                    {items.map((item, i) => (
                        <Col key={i} lg={oddColumn ? 4 : 3} md={6} className="portfolio-item">
                            <div role="button" tabIndex={i} style={{ cursor: "pointer" }} onClick={() => { setIsOpen(true); setPhotoIndex(i); }} onKeyDown={() => { setIsOpen(true); setPhotoIndex(i); }}>
                                <Img fluid={imageInfo[i].thumbnail} className="img-fluid" alt={item.elements.title.value} />
                            </div>
                            {
                                (imageInfo[i].downloadUrl && (
                                    <a href={imageInfo[i].downloadUrl} download className="portfolio-info" title={item.elements.title.value}>
                                        <h4>{item.elements.title.value}</h4>
                                        <span className="details-link">
                                            <FaDownload />
                                        </span>
                                    </a>
                                )) || (
                                    <div className="portfolio-info">
                                        <h4>{item.elements.title.value}</h4>
                                    </div>
                                )
                            }

                        </Col>
                    ))}
                </Row>
            </Container>
            {isOpen && (
                <Lightbox
                    enableZoom={false}
                    mainSrc={imageInfo[photoIndex].imageUrl}
                    nextSrc={imageInfo.length > 1 ? imageInfo[(photoIndex + 1) % imageInfo.length].imageUrl : null}
                    prevSrc={imageInfo.length > 1 ? imageInfo[(photoIndex + imageInfo.length - 1) % imageInfo.length].imageUrl : null}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() => setPhotoIndex((photoIndex + imageInfo.length - 1) % imageInfo.length)}
                    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % imageInfo.length)}
                    toolbarButtons={toolbarButtons}
                />
            )}
        </section>
    )
}

AssetList.propTypes = {
    anchor: PropTypes.string,
    sectionTitle: PropTypes.string,
    items: PropTypes.array.isRequired,
    oddColumn: PropTypes.bool.isRequired,
}

export default AssetList