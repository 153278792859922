import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Navigation from "./navigation"

const Header = ({ lang, siteTitle, navItems, breadcrumb, otherLangUrl }) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(height: 40) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  const [scroll, setScroll] = useState(false)
  useEffect(() => {
    const listener = () => {
      setScroll(window.scrollY > 10)
    }
    window.addEventListener("scroll", listener)
    return () => window.removeEventListener("scroll", listener)
  }, [])

  return (
    <header id="header" className={"fixed-top header-inner-pages" + (scroll ? " header-scrolled" : "")}>
      <div className="container d-flex align-items-center">
        <h1 className="logo mr-auto">
          <Link to={`/${lang}/`}>
            <Img fixed={data.logo.childImageSharp.fixed} alt={siteTitle} />
          </Link>
        </h1>
        <Navigation navItems={navItems} lang={lang} breadcrumb={breadcrumb} otherLangUrl={otherLangUrl} />
      </div>
    </header>
  )
}

Header.propTypes = {
  lang: PropTypes.string.isRequired,
  siteTitle: PropTypes.string,
  navItems: PropTypes.array.isRequired,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
